import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
  token = '';
  email = '';
  resetPwdForm = new FormGroup({
    new_password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirm_password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  }, {
    validators: this.mustMatch('new_password', 'confirm_password')
  });
  constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
          console.log('params:', params['token']);
          this.token = params['token'];
        });

    this.route.queryParams
      .subscribe(
        (params: Params) => {
          if (params['email']) {
            console.log('params:', params['email']);
            this.email = params['email'];
          }
        });
  }

  private mustMatch(firstControl: string, secondControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(firstControl)?.value;
      const confirm = control.get(secondControl)?.value;
      if (password != confirm) { return { 'noMatch': true } }
      return null
    }
  }

  resetPassword() {
    let new_pass = this.resetPwdForm.get('new_password')?.value;
    let confirm_pass = this.resetPwdForm.get('confirm_password')?.value;
    if (new_pass && confirm_pass) {
      this.authenticationService.resetPasswordServer(this.token, this.email,
        new_pass, confirm_pass);
    }
  }
}
