import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MainComponent } from './main/main.component';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { spinnerInterceptor } from './interceptors/spinner.interceptor';
import { authInterceptor } from './interceptors/auth.interceptor';
import { httpErrorInterceptor } from './interceptors/http-error.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { HeaderComponent } from './header/header.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import localeIt from '@angular/common/locales/it';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DirectivesModule } from './modules/directives/directives.module';
import { StoreModule, provideStore } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreDevtoolsModule, provideStoreDevtools } from '@ngrx/store-devtools';
import { UserEffects } from './storage-ngrx/user/user.effects';
import { userReducer } from './storage-ngrx/user/user.reducer';
import { pageReducer} from './storage-ngrx/page/page.reducer';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { companyReducer } from './storage-ngrx/company/company.reducer';
import { CompanyEffects } from './storage-ngrx/company/company.effects';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    MainComponent,
    FooterComponent,
    HttpClientModule,
    HeaderComponent,
    NgxSpinnerModule,
    NgxMaterialTimepickerModule.setOpts('it-IT'),
    MatDatepickerModule,
    MatNativeDateModule,
    DirectivesModule,
    StoreModule.forRoot({
      page: pageReducer,
      user: userReducer,
      company: companyReducer
    }),
    EffectsModule.forRoot([UserEffects, CompanyEffects]),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  providers: [
    provideAnimationsAsync(),
    CookieService,
    provideHttpClient(withInterceptors([spinnerInterceptor, authInterceptor, httpErrorInterceptor])),
    // provideStore({user: userReducer}),
    // provideEffects([UserEffects]),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
