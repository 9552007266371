import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './main/main-index/login/login.component';
import { loginGuard } from './guards/login.guard';
import { ResetPasswordComponent } from './main/main-index/reset-password/reset-password.component';
import { HomeComponent } from './main/main-home/pages/home/home.component';
import { authGuard } from './guards/auth.guard';
import { homeGuard } from './guards/home.guard';
import { ErrorComponent } from './main/main-home/pages/error/error.component';
import { CalendarComponent } from './main/main-home/pages/calendar/calendar.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard]
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    canActivate: [loginGuard]
  },
  {
    path: '',
    component: HomeComponent,
    // se loggato vai alla home, se non loggato vai a login
    canActivate: [authGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [homeGuard]
    // redirectTo: ''
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'calendar',
    component: CalendarComponent
  },
  {
    path: 'anagrafica',
    loadChildren: () => import('./main/main-home/pages/anagrafica/anagrafica.module').then(m => m.AnagraficaModule)
  },

  {
    path: 'lavorazioni/commesse',
    loadChildren: () => import('./main/main-home/pages/lavorazioni/commesse/commesse.module').then(m => m.CommesseModule)
  },

  {
    path: 'lavorazioni/rdi',
    loadChildren: () => import('./main/main-home/pages/lavorazioni/rdi/rdi.module').then(m => m.RdiModule)
  },

  {
    path: 'lavorazioni/report',
    loadChildren: () => import('./main/main-home/pages/lavorazioni/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./main/main-home/pages/settings/setting.module').then(m => m.SettingsModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('./main/main-home/pages/tickets/tickets.module').then(m => m.TicketsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
