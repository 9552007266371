import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IconsComponent } from '../../components/icons/icons.component';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../storage-ngrx/user/user.reducer';
import * as UserActions from '../../storage-ngrx/user/user.actions';
import { User } from '../../interfaces/user';
import { Company } from '../../interfaces/company';
import { Connect } from '../../classes/connect';
import { PageState } from '../../storage-ngrx/page/page.state';
import * as PageActions from '../../storage-ngrx/page/page.actions';
import { map, Observable } from 'rxjs';
import { CompanyState } from '../../storage-ngrx/company/company.reducer';
import { loadCompany } from '../../storage-ngrx/company/company.actions';

interface Post {
  userId: number;
  id: number;
  title: string;
  body: string;
}

@Component({
  selector: 'app-header-home',
  standalone: true,
  imports: [CommonModule, MatIconModule, ReactiveFormsModule, MatAutocompleteModule, IconsComponent, MatMenuModule],
  templateUrl: './header-home.component.html',
  styleUrl: './header-home.component.scss'
})
export class HeaderHomeComponent {
  // si può usare per manovrare il button che è nel padre ed è stato inserito con ng-content nel html
  // @ContentChild('button_logo_w',{static: false}) logo_chiocciola: ElementRef;

  // icona home
  @ViewChild('home_icon_id', { static: false }) obj_icons_home!: IconsComponent;
  // icona calendario
  @ViewChild('calendar_icon_id', { static: false }) obj_icons_calendar!: IconsComponent;
  // icona notifiche
  @ViewChild('notification_icon_id', { static: false }) obj_icons_notification!: IconsComponent;
  // testo agenda
  @ViewChild('calendar_div_id', { static: false }) div_calendar!: ElementRef;
  control = new UntypedFormControl();
  // options: User[] = [
  //   { name: 'Mary', id: 1 },
  //   { name: 'Shelley', id: 2 },
  //   { name: 'Igor', id: 3 }
  // ];
  // filteredOptions: Observable<User[]>;
  // notification: Observable<Post[]>;
  notification: Post[] = [];
  // riquadro notifiche
  @ViewChild('div_notifiche', { static: false }) div_notifiche: any;
  // testo notifiche
  @ViewChild('notifiche_div_id', { static: false }) notifiche_div_id!: ElementRef;
  spinner_notification = false;
  // nomeUser = 'Utente';
  nickname$!: Observable<string | null>;
  heightIcon$!: Observable<string | null>;
  logoIcon$!: Observable<string | null>;

  idazienda = 0;
  agendaPermesso = true;
  cssIconaAgenda = '';
  // logoIcon = 'loghi/walley_w.png';
  // heightIcon: string = '25';
  constructor(private renderer: Renderer2, private authenticationService: AuthenticationService,
    private router: Router, private shareService: SharedService,
    private store: Store<{ user: UserState, page: PageState, company: CompanyState }>) {
    // this.authenticationService.getInfoUserLogged().subscribe(
    //   (user: UserLogged) => {
    //     this.authenticationService.userLogged = user;
    //     // this.nomeUser = user ? user.name : '';
    //     if (user && user.idazienda == 4) {
    //       this.heightIcon = '';
    //       this.logoIcon = 'loghi/weco_w.png';
    //     }
    //   }
    // )

    // try {
    //   this.connectServerService.postRequestLaraAsync(Connect.urlServerLaraApi, 'auth/me',
    //     {})
    //     .then((userDates: any) => {
    //       // console.log(userDates);
    //       this.authenticationService.userLogged = userDates;
    //       // creo l'oggetto User e setto la variabile con il contenuto
    //       // const obj_userlog = new UserLogged('andrea', 1, 1, 1, 'andrea@wall.it', true, false);
    //       this.nomeUser = this.authenticationService.userLogged ? this.authenticationService.userLogged.name : '';
    //       if (this.authenticationService.userLogged && this.authenticationService.userLogged.idazienda == 4) {
    //         this.heightIcon = '';
    //         this.logoIcon = 'loghi/weco_w.png';
    //       }
    //     });
    // } catch (e) {
    //   console.log('error: ');
    //   console.log(e);
    // }
    // quando ho fatto il login emetto un evento che prendo qui e che uso per le icone
    // this.authenticationService.userLoginIn.subscribe(
    //   (user) => {
    //     console.log('header evento emesso per utente: :');
    //     console.log(user);
    //   },
    //   () => {

    //   }
    // );
  }

  logOut(): void {
    this.authenticationService.logoutServer();
  }

  /**
   * Clicco sull'icona home, cambio font, metto Home come titolo e vado alla home
   */
  goHome(): void {
    this.obj_icons_home.fontsetValue = 'material-icons';
    this.obj_icons_calendar.fontsetValue = 'material-icons-outlined';
    this.renderer.addClass(this.div_calendar.nativeElement, 'font-weight-light');
    // this.shareService.changeTitle('Home');
    this.store.dispatch(PageActions.setTitle({ title: 'Home' }))
    this.router.navigate(['']);
  }

  /**
   * Clicco sull'icona calendar, cambio font e vado all' agenda
   */
  goCalendar(): void {
    this.obj_icons_calendar.fontsetValue = 'material-icons';
    this.obj_icons_home.fontsetValue = 'material-icons-outlined';
    // this.renderer.addClass(this.div_calendar, 'font-weight-light');
    this.renderer.removeClass(this.div_calendar.nativeElement, 'font-weight-light');
    this.router.navigate(['calendar']);
  }

  /*
  @ViewChild('search_anagrafica', {static: false}) idsearch;
      searchAnagrafica() {
          if (this.idsearch) {
              fromEvent(this.idsearch, 'keyup')
                  .pipe(
                      map((search_value: any) => search_value.target.value),
                      filter((search_value: string) => search_value.length > 2),
                      debounceTime(1200),
                      tap(() => this.cleanValue()),
                      switchMap((search_value: string) => this.getAnagrafica(search_value))
                  ).subscribe();
          }
      }

      cleanValue() {

      }

      getAnagrafica(search: string) {

      }*/

  ngOnInit(): void {
    // console.log('-----HeaderHome');
    // console.log(this.router.url);
    // barra di ricerca
    /*this.filteredOptions = this.control.valueChanges
        .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            filter((value: string) => value.length > 1),
            debounceTime(600),
            tap(() => this.cleanSearch()),
            // map(name => name ? this._filter(name) : this.options.slice())
            // map(name => name ? this._filter(name) : [])
            switchMap((value: string) => this.service_search.getAnagrafica('/' + value))
        );*/
    // console.log('HeaderHomeComponent: ngOnInit');

    // this.notification = this.service_search.getAnagrafica('?userId=1');
    /*this.service_search.getAnagrafica('?userId=1').subscribe(
        data => {
            this.notification = data;
        }, (err) => {
            console.log('errore nella ricerca');
            console.log(err);
        });*/

    // si richiama al server
    this.store.dispatch(UserActions.loadUserInfo());
    this.store.dispatch(UserActions.loadUserPermissions());
    this.store.dispatch(UserActions.loadUserCompanyInfo());
    this.store.dispatch(loadCompany());

    this.nickname$ = this.store.select(state => state.user.userInfo).pipe(
      map((val: User | null) => val ? val.nickname : '')
    );
    const companyInfo$ = this.store.select(state => state.user.companyInfo);

    this.heightIcon$ = companyInfo$.pipe(
      map((val: Company | null) => val?.height_logoonwalley ?? null)
    );

    this.logoIcon$ = companyInfo$.pipe(
      map((val: Company | null) => val?.src_logoonwalley ? Connect.urlServerLaraApi + val.src_logoonwalley : null)
    );
  }


  addNotifications(): void {
    // scrollHeight : altezza del contenuto (fisso per ogni caricamento)
    // clientHeight: altezza del div (fisso)
    // scrollTop: posizione top quando si scrolla
    if (this.div_notifiche.nativeElement.scrollHeight - this.div_notifiche.nativeElement.scrollTop -
      this.div_notifiche.nativeElement.clientHeight < 3) {
      // console.log('8 scrollllllll');
      this.spinner_notification = true;
      /*this.service_search.getAnagrafica('?userId=1').subscribe(data => {
          this.spinner_notification = false;
          this.notification.push(...data);
      });*/
    }

  }

  openNotification(): void {
    this.obj_icons_notification.matBadge_value = null;
    // quando apro
    if (this.obj_icons_notification.fontsetValue === 'material-icons-outlined') {
      // console.log('ciaooooo');
      this.renderer.removeClass(this.notifiche_div_id.nativeElement, 'font-weight-light');
      this.obj_icons_notification.fontsetValue = 'material-icons';
    } else {
      this.renderer.addClass(this.notifiche_div_id.nativeElement, 'font-weight-light');
      this.obj_icons_notification.fontsetValue = 'material-icons-outlined';
    }
  }

  cleanSearch(): void {
  }

  displayFn(user?: User): string | undefined {
    return user ? user.name : undefined;
  }

  // private _filter(name: string): User[] {
  //   const filterValue = name.toLowerCase();
  //   return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  // }

  // private _normalizeValue(value: string): string {
  //   return value.toLowerCase().replace(/\s/g, '');
  // }
  goToProfile(){
    this.router.navigate(['settings/userProfile'])
  }
}
