import { Component, Renderer2 } from '@angular/core';
import { SharedService } from '../../../../services/shared.service';
import { Subscription } from 'rxjs';
import { IconsComponent } from '../../../../components/icons/icons.component';
import { NavbarService } from '../../../../header/services/navbar.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  private callOne = true;
  subscriptionIcon: Subscription;

  constructor(private shareService: SharedService, private navbarService: NavbarService, private renderer: Renderer2) {
    this.subscriptionIcon = shareService.child_home_icon.subscribe(
      (data: any) => {
        if (data) {
          // icona home sull'header
          if (data instanceof IconsComponent) {
            // console.log('si mat2: ' + data);
            // metto un minimo timeout perchè cambiava il valore incontemporanea a quando lo assegnava.
            setTimeout(() => this.navbarService.changeIconHomeHeader(this.renderer, data), 0);
          }
        }
      },
      // error => {
      //     console.log('Errore cambio icona header home: ' + error);
      // }
    );
  }

  ngOnInit(): void {
    // console.log('1 PHomeComponent: ngOnInit');

  }

  ngAfterViewInit(): void {
    // console.log('2 PHomeComponent: ngAfterViewInit');
    // console.log('--> user: ' + User.prova);
  }

  ngAfterViewChecked(): void {
    if (this.callOne) {
      this.callOne = false;
      this.shareService.sendDivMenu('p-home');
    }
    // console.log('3 PHomeComponent: ngAfterViewChecked');

  }

  ngAfterContentChecked(): void {
    // console.log('4 PHomeComponent: ngAfterContentChecked');
    // console.log('--> user: ' + User.prova);
  }

  ngAfterContentInit(): void {
    // console.log('5 PHomeComponent: ngAfterContentInit');
    // console.log('--> user: ' + User.prova);
  }

  ngOnDestroy(): void {
    // console.log('8 PHomeComponent: ngOnDestroy');
    this.subscriptionIcon.unsubscribe();
  }
}
