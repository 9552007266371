<nav class="navbar navbar-dark bg-dark flex-nowrap
                fixed-top mt-0 pt-0 mb-0 pb-0 justify-content-start justify-content-sm-between">
  <div class="d-flex h-100 w-50 ">
    <!-- ng-content è usato per inserire il pulsante sotto commentato, dal componente padre -->
    <ng-content></ng-content>
    <!--<button class="navbar-brand bg-dark border-0 a_clean" (click)="toggleSidenav()" >
          <img src="../../../assets/images/icona_2.png" width="30" height="30" alt="">
        </button>-->
    <form class="d-none d-sm-block navbar-brand w-75 form-search text-secondary">
      <div class="">
        <input class="form-control" type="search" placeholder="Cerca cliente, fornitore, prospect" aria-label="Search"
          readonly disabled>
      </div>
      <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    <a href="#" routerLink="page/{{option.id}}">
                        {{option.title}}
                    </a>
                </mat-option>
            </mat-autocomplete> -->
    </form>
  </div>
  <div class="d-flex pb-0">
    <button [disabled]="true" class="navbar-brand d-sm-none text-center dim_text_mini pb-0 a_clean text-secondary"
      href="#">
      <mat-icon class=" " aria-hidden="false" aria-label="Search icon">search</mat-icon>
    </button>
    <button class="navbar-brand text-center dim_text_mini pb-0 a_clean me-0 me-lg-2" (click)="goHome()">
      <app-icons [arialabelValue]="'Home icon'" [id_value]="'id_icon_home'" [ariahiddenValue]="'false'" icon="home"
        [fontsetValue]="'material-icons-outlined'" [classValue]="'mt-1 align-middle'" #home_icon_id></app-icons>
      <!-- <mat-icon class=" align-middle" aria-hidden="false" aria-label="Home icon" fontIcon="home" ></mat-icon> -->
      <div class="d-none d-sm-block ">Home</div>
    </button>
    <button [disabled]="!agendaPermesso" [class]="cssIconaAgenda" class="navbar-brand text-center dim_text_mini pb-0 a_clean me-0
         " (click)="goCalendar()">

      <!-- <app-icons [arialabelValue]="'Calendar icon'" [ariahiddenValue]="'false'" [icon]="'calendar_today'"
        [classValue]="'mt-1 align-middle'" [fontsetValue]="'material-icons-outlined'" #calendar_icon_id></app-icons> -->
        <mat-icon class="mt-1 align-middle"  matBadgeColor="warn" matBadgeSize="medium" aria-hidden="false"
          aria-label="Calendar icon" fontIcon="calendar_today" #calendar_icon_id></mat-icon>
      <div class="d-none d-sm-block  mb-0 font-weight-light" #calendar_div_id>Agenda</div>
    </button>
    <div class="dropdown">
      <button [disabled]="true" id="dropdownNotification"
        class="navbar-brand text-center dim_text_mini pb-0 a_clean me-0 text-secondary" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" (click)="openNotification()">
        <!-- <app-icons [arialabelValue]="'Notification icon'" [matBadge_value]="15" [matBadgeColor_value]="'warn'"
          [matBadgeSize_value]="'medium'" [ariahiddenValue]="'false'" [icon]="'notifications'"
          [classValue]="'mt-1 align-middle'" [fontsetValue]="'material-icons-outlined'"
          #notification_icon_id></app-icons> -->
        <mat-icon class="mt-1 align-middle" matBadge="15" matBadgeColor="warn" matBadgeSize="medium" aria-hidden="false"
          aria-label="Notification icon" fontIcon="notifications"></mat-icon>
        <div class="d-none d-sm-block mt-0 font-weight-light" #notifiche_div_id>Notifiche</div>
      </button>
      <form>
        <div class="dropdown-menu dropdown-menu-right div_notifiche bg-light border-0 pt-0 pb-0"
          aria-labelledby="dropdownNotification">
          <div *ngIf="spinner_notification"
            class="d-flex position-absolute align-content-center flex-wrap overflow-hidden justify-content-center h-100 w-100 bg-secondary"
            style="z-index: 10; opacity: 0.8;">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="list-group h-100 overflow-auto" (scroll)="addNotifications()" #div_notifiche>
            <!--<div style="position: absolute; width: 100%; height: 100%; border: 1px solid red; align-content: center; text-align: center; z-index: 10;">-->

            <a *ngFor="let notifica of notification" href="#" class="list-group-item list-group-item-action">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">List group item heading</h5>
                <small>3 days ago</small>
              </div>
              <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget
                risus varius blandit.</p>
              <small>Donec id elit non mi porta.</small>
            </a>
          </div>
          <!--<button class="dropdown-item" type="button">Action</button>
                    <button class="dropdown-item" type="button">Another action</button>
                    <button class="dropdown-item" type="button">Something else here</button>-->
        </div>
      </form>
    </div>


    <button [disabled]="true" class="navbar-brand text-center dim_text_mini pb-0 a_clean me-0 text-secondary"
      [matMenuTriggerFor]="menuHelp" href="#">
      <app-icons [arialabelValue]="'Help icon'" [ariahiddenValue]="'false'" [icon]="'help_outline'"
        [classValue]="'mt-1 align-middle'"></app-icons>
      <!--<mat-icon class=" mt-1" aria-hidden="false" aria-label="Help icon" >help_outline</mat-icon>-->
      <div class="d-none d-sm-block  mb-0 font-weight-light">Help</div>
    </button>
    <mat-menu #menuHelp="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item class="a_clean">
          <mat-icon>info</mat-icon>
          <span matTooltip="ciao mondo">
            Black
          </span>
        </button>
        <button mat-menu-item class="a_clean">
          <mat-icon>question_answer</mat-icon>
          <span>Apri una segnalazione</span>
        </button>
        <!--<button mat-menu-item disabled>-->
        <button mat-menu-item class="a_clean">
          <mat-icon>call</mat-icon>
          <span>Assistenza Wallnet</span>
        </button>
      </ng-template>
    </mat-menu>
    <button class="navbar-brand text-center dim_text_mini pb-0 a_clean" [matMenuTriggerFor]="menuUser">
      <app-icons [arialabelValue]="'User icon'" [ariahiddenValue]="'false'" [icon]="'supervised_user_circle'"
        [classValue]="'mt-1 align-middle'"></app-icons>
      <div class="d-none d-sm-block  mb-0 font-weight-light">{{nickname$ | async}}</div>
    </button>
    <mat-menu #menuUser="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item class="a_clean" (click)="goToProfile()" >
          <mat-icon>account_circle</mat-icon>
          <span>{{nickname$ | async}}</span>
        </button>
        <!--<button mat-menu-item disabled>-->
        <!-- <button mat-menu-item class="a_clean" disabled>
          <mat-icon>credit_card</mat-icon>
          <span>Trasferte</span>
        </button>
        <button mat-menu-item class="a_clean" disabled>
          <mat-icon>free_breakfast</mat-icon>
          <span>Permessi e Ferie</span>
        </button> -->
        <button mat-menu-item (click)="logOut()" class="a_clean">
          <mat-icon>exit_to_app</mat-icon>
          <span>Log out</span>
        </button>
      </ng-template>
    </mat-menu>
    <!--<a class="navbar-brand d-none d-md-block" href="#">
            <img src="../../../assets/images/icona_2.png" width="10" height="10" alt="">
        </a>-->
    <!-- <app-icons [tipoIcona]="2" [ariahiddenValue]="'false'" class="align-self-center d-none d-lg-block "
      [srcIcon]="logoIcon" [widthValue]="'91'" [heightValue]="heightIcon" id="icona_walley"></app-icons> -->

      <img [attr.src]="logoIcon$ | async" [attr.width]="91" [attr.height]="heightIcon$ | async"
      class="align-self-center d-none d-lg-block " id="icona_walley">
  </div>
</nav>
