import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const homeGuard: CanActivateFn = (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  console.log('autenticazione', authenticationService.isLoggedIn);
  if (authenticationService.isLoggedIn) {
    return true;
  } else {
    // console.log('home guard dentro');
    router.navigate(['login']);
    return false;
  }
};
